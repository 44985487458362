
























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Issue } from '@/models/Issue.model'
import IssueStatus from '@/main/issues/components/IssueStatus.vue'
import Estimation from '@/main/issues/components/Estimation.vue'
import TaskListDraggable from '@/main/issues/components/task/TaskListDraggable.vue'

@Component({
  components: { TaskListDraggable, Estimation, IssueStatus }
})
export default class IssueListItem extends Vue {
  @Prop()
  private issue!: Issue
  @Prop({ default: false })
  private dense!: boolean

  @Prop({ default: false })
  private showTaskList!: boolean

  get issueTitle() {
    return `${this.issue.iri()} - ${this.issue.title}`
  }
}
