


























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Sprint } from '@/models/Sprint.model'

@Component
export default class SprintStatusBar extends Vue {
  @Prop()
  private sprint!: Sprint
}
