



















import { Component, Prop, Vue } from 'vue-property-decorator'
import axios, { AxiosResponse } from 'axios'
import { Sprint } from '@/models/Sprint.model'
import SprintStatusBar from '@/main/sprint/component/SprintStatusBar.vue'
import IssueListItem from '@/main/issues/components/IssueListItem.vue'

@Component({
  components: { IssueListItem, SprintStatusBar }
})
export default class SprintBoard extends Vue {
  @Prop()
  private sprintId!: number
  private sprint: Sprint | null = null

  async created() {
    this.sprint = await Sprint.createFromResponse(await axios.get<AxiosResponse>('/sprints/' + this.sprintId))
  }
}
